<template>
  <b-sidebar
    id="create-department-teacher-sidebar"
    hide-footer
    right 
    ref="createRelationSidebar"
    bg-variant="white"
    backdrop

  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Add Teachers in Department</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Select Teacher</span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="teacher" placeholder="Select Teacher" :options="teachers" :reduce="option => option.id" autoscroll :clearable="true"></v-select>  
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">
              Select Department
            </span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="department" placeholder="Select Department" :options="departments" :reduce="option => option.id" autoscroll :clearable="true"></v-select>  
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">
              Head of Department
            </span>
            <b-form-group class="mb-0">
              <v-select label="name" v-model="is_head" placeholder="Select Yes If HOD" :options="hod" :reduce="option => option.id" autoscroll :clearable="true"></v-select>  
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          pill
          @click="submit()"
        >
          Add
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      teacher: "",
      department: "",
      is_head: "",
      teachers: [],
      departments: [],
      hod: [
        { name: "YES", id: true },
        { name: "NO", id: false },
      ],
    };
  },
  async mounted() {
    const res_teachers = await this.getTeachersDropDown();
    this.teachers = res_teachers.data;

    const res_departments = await this.getDepartmentDropDown();
    this.departments = res_departments.data;
  },
  methods: {
    ...mapActions({
      createRelTeacherDepartment: "appData/createRelTeacherDepartment",
      getTeachersDropDown: "appData/getTeachersDropDown",
      getDepartmentDropDown: "appData/getDepartmentDropDown",
    }),


    async submit() {
      try {
        const formData = new FormData();
        formData.append("teacher", this.teacher);        
        formData.append("department", this.department);        
        formData.append("is_head", this.is_head);        
        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 
        const res = await this.createRelTeacherDepartment(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Relation created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.createRelationSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.teacher = "";      
      this.department = "";      
      this.is_head = "";      
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" })
  },
};
</script>

<style scoped></style>
